import {createRouter, createWebHistory} from "vue-router";

let routes = [];
routes = [
  {
    path: "/admin",
    name: "admin",
    component: () => import("../components/admin/HomeAdmin.vue"),
  },
  {
      path: "/admin/employee",
      name: "employee",
      component: () => import("@/views/Admin/Employee.vue"),
  },
  {
      path: "/admin/adminview",
      name: "admin view",
      component: () => import("@/views/Admin/AdminView"),
  },
  {
      path: "/admin/type",
      name: "type",
      component: () => import("@/views/Admin/TypeView.vue"),
  },
  {
      path: "/admin/unit",
      name: "unit",
      component: () => import("@/views/Admin/UnitView.vue"),
  },
  {
      path: "/admin/product",
      name: "product",
      component: () => import("@/views/Admin/ProductView.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
