<template>
  <div v-if="$store.getters.logedIn === true">
    <NavberAdmin v-if="$store.getters.roles === 'admin'" />
    <NavberEmployee v-if="$store.getters.roles === 'employee'" />
    <router-view />
  </div>
  <div v-else>
    <LoginPage />
  </div>
  <Toast />
  <ConfirmDialog />
  <!-- dialog loading-->
  <Dialog class="border-0 shadow-none" :draggable="false" v-model:visible="$store.getters.loading" :closable="false"
    :modal="true">
    <ProgressSpinner style="width: 80px; height: 80px" strokeWidth="5" fill="var(--surface-ground)"
      animationDuration=".5s" aria-label="Custom ProgressSpinner" /><br />
  </Dialog>
</template>

<script>
import LoginPage from '@/views/LoginView.vue';
import NavberAdmin from '@/components/template/NavbarAdmin.vue';
import NavberEmployee from '@/components/template/NavbarEmployee.vue';
import axios from 'axios';
// import jwtDecode from "jwt-decode";
export default {

  components: {
    LoginPage,
    NavberAdmin,
    NavberEmployee
  },
  async beforeCreate() {
    
    this.$store.commit('setLoading', true);
    if (localStorage.getItem("token") !== null) {
      await axios.get(`${process.env.VUE_APP_BOQ}/login/me/`, {
        headers: {
          "token": `${localStorage.getItem("token")}`,
        },
      }).then(async(res) => {
        const data = {
          logedIn: true,
          name: res.data.data.name,
          username: res.data.data.username,
          roles: res.data.data.roles
        };
        this.$store.commit("setLogin", data);
        this.$store.commit('setLoading', false);
        
        if (this.$store.getters.roles === 'admin') {
          this.$router.push("/admin")
        } else if (this.$store.getters.roles === 'employee') {
          this.$router.push("/listquotation")
        } 
      }).catch((error)=>{
        localStorage.clear();
        this.$router.push("/login");
        return false
        });
    } else {
      localStorage.clear();
      this.$router.push("/login");
    }
  }
}
</script>

<style>
/* #app {

  font-family: 'Mali';
  src: url('../src/Mali/Mali-Medium.ttf') format('truetype');

} */
</style>
<style>
/* @font-face {
  font-family: 'Mali';
  src: url('../src/Mali/Mali-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
</style>