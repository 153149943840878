import { createStore } from "vuex";

const store = createStore({
    state: {
        isLoading: false,
        logedIn: false,
        name: '',
        username: '',
        id: '',
        roles: '', //ใช้กำหมดสิทธิ์ admin or employee
        token: localStorage.getItem("token"),

   
    },
    getters: {
        logedIn: (state) => state.logedIn,
        name: (state) => state.name,
        username: (state) => state.username,
        id: (state) => state.id,
        roles: (state) => state.roles,
        token: (state) => state.token,

    },
    mutations: {
        setLoading(state, item) {
            state.isLoading = item;
        },
        setLogin(state, item) {
            state.logedIn = item.logedIn;
            state.name = item.name;
            state.username = item.username;
            state.roles = item.roles;
        },
        ClearLogin(state) {
            state.logedIn = false;
            state.name = '';
            state.username = '';
            state.roles = '';
        },
        setIduser(state, val) {
            state.id = val
        }
    },
    actions: {},
    modules: {},
})

export default store;