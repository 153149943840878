import {createRouter, createWebHistory} from "vue-router";

let routes = [];
routes = [
  {
    path: "/listquotation",
    name: "listquotation",
    component: () => import("../components/employee/ListquotationView.vue"),
  },
  {
    path: "/createquotation",
    name: "createquotation",
    component: () => import("../components/employee/CreatequotationView.vue"),
  },
  {
    path: "/printquotataion/:id",
    name: 'printquotataion',
    component: () => import("../components/employee/GenDoc/PrintQuotation.vue")
  },
  {
    path: "/editquotation/:id",
    name: 'editquotation',
    component: () => import('../components/employee/GenDoc/EditQuotation.vue'),
  },
  {
    path: "/employee",
    name: "employee",
    component: () => import("@/views/Admin/Employee.vue")
  },
  {
    path: "/type",
    name: "type",
    component: () => import("@/views/Admin/TypeView.vue"),
  },
  {
      path: "/unit",
      name: "unit",
      component: () => import("@/views/Admin/UnitView.vue"),
  },
  {
      path: "/product",
      name: "product",
      component: () => import("@/views/Admin/ProductView.vue"),
  },
  {
    path: "/administration",
    name: "administration",
    component: () => import("@/views/Admin/AdministrationView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
