<template>
    <div>
        <Menubar :model="items" class="custom-menubar">
            <template #start>
                <!-- <img alt="logo" :src="require('@/assets/pethub.png')" class="logo" width="50" /> -->
                <h3>Employee</h3>
            </template>
            <template #end>
                <Button class="p-button-danger" label="ออกจากระบบ" icon="pi pi-power-off" @click="logout()" />
            </template>
        </Menubar>
    </div>
</template>
<script>
import Menubar from "primevue/menubar";
import { ref } from "vue";
export default {
    components: {
        Menubar
    },
    setup() {
        const items = ref([
            {
                label: "รายการ ใบประมาณ",
                to: "/listquotation",
            },
            {
                label: "ทำรายการ ใบประมาณ",
                to: "/createquotation",
            },
            {
                label: "พนักงาน",
                to: "/employee"
            },
            {
                label: "งาน",
                items: [
                    {
                        label: "ประเภทงาน",
                        to: '/type',
                    },
                    {
                        label: "หน่วยของงาน",
                        to: '/unit',
                    },
                    {
                        label: "งาน",
                        to: '/product',
                    },
                    {
                        label: "ค่าดำเนินการ",
                        to: '/administration',
                    },
                ]
            },
        ])
        return { items };
    },
    methods: {
        logout() {
            this.$confirm.require({
                message: "ต้องการออกจากระบบนี้?",
                header: "ออกจากระบบ",
                icon: "pi pi-exclamation-triangle",
                acceptLabel: "ออกจากระบบ",
                acceptClass: "p-button-danger",
                acceptIcon: "pi pi-fw pi-power-off",
                rejectLabel: "ยกเลิก",
                accept: async () => {
                    localStorage.clear();
                    this.$store.commit("ClearLogin");
                },
            });
        },
    }
}
</script>
