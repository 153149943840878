<template>
  <section>
    <Transition name="slide-down-fade" appear>
    <div class="wrapper">
      <h1>Wellcome BOQ</h1>
      <div class="input-box">
        <input 
          type="text" 
          placeholder="Username" 
          required 
          v-model="username"
        />
        <i class="bx bxs-user"></i>
      </div>
      <div class="input-box">
        <input 
          type="password" 
          placeholder="Password" 
          required 
          v-model="password"
        />
        <i class="bx bxs-lock"></i>
      </div>
      
      <Button @click="logIn()" :loading="loading" class="w-full border-none hover:bg-gray-800" label="Login" style="background-color:#000;"/>
    </div>
    </Transition>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    username: '',
    password: '',
    loading:false
  }),
  methods: {
    async logIn() {
      this.loading = true;
      if (this.username === '' || this.password === '') {
        this.loading = false;
        this.$toast.add({
          severity: "warn",
          summary: "แจ้งเตือน",
          detail: "กรุณากรอกข้อมูล username และ password ให้ถูกต้อง",
          life: 3000,
        });    
        return false;
      } 
      
      await axios.post(`${process.env.VUE_APP_BOQ}/login`, {
        username: this.username,
        password: this.password,
      }).then(async (res) => {
        if (res.data.status=== true) {
          this.$toast.add({
            severity: "success",
            summary: "ล็อคอินสำเร็จ",
            detail: "ล็อคอินสำเร็จแล้ว",
            life: 3000,
          });
          this.loading = false;
          localStorage.setItem("token", res.data.token);
          window.location.assign('/');
        } else {
          this.$toast.add({
            severity: "error",
            summary: "เกิดข้อผิดพลาด",
            detail: res.data.message,
            life: 3000,
          });
          this.loading = false;
          return 
        }
      }).catch((error) => {
        this.$toast.add({
            severity: "error",
            summary: "เกิดข้อผิดพลาด",
            detail: error,
            life: 3000,
          });
        this.loading = false;
      })
    }
  },
};
</script>

<style>
</style>


<style scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-image: url("../assets/bg-login.jpg");
  background-size: cover;
}

.wrapper {
  width: 420px;
  background: #f7f7f7c9;
  color: #323643;
  border-radius: 12px;
  padding: 30px 40px;
}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  background: #eeeff1;
  border-radius: 10px;
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid var(--main-gray);
  border-radius: 10px;
  font-size: 16px;
  color: var(--main-black);
  padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
  color: #606470;
}

.input-box i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.wrapper .btn {
  width: 100%;
  height: 45px;
  background: #323643;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #f7f7f7;
  font-weight: 600;
}

.slide-down-fade-enter-from {
		opacity: 0;
		transform: translateY(-30px);
	}

	.slide-down-fade-enter-active {
		transition: all 2s ease;
	}
</style>