<template>
  <div>
    <Menubar :model="items" class="custom-menubar">
      <template #start>
        <h3>Admin</h3>
      </template>
      <template #end>
        <Button class="p-button-danger" label="ออกจากระบบ" icon="pi pi-power-off" @click="logout()" />
      </template>
    </Menubar>
  </div>
</template>
<script>
import Menubar from "primevue/menubar";
import { ref } from "vue";
export default {
  components: {
    Menubar
  },
  setup() {
    const items = ref([
      {
        label: "หน้าหลัก",
        to: "/admin",
      },
      {
        label: "Admin",
        to: "/admin/adminview",
      },
      {
        label: "พนักงาน",
        to: "/admin/employee",
      },
      {
        label: "งาน",
        items: [
          {
            label: "ประเภทงาน",
            to: '/admin/type',
          },
          {
            label: "หน่วยของงาน",
            to: '/admin/unit',
          },
          {
            label: "งาน",
            to: '/admin/product',
          },
        ],
      },
    ])
    return { items };
  },
  methods: {
    logout() {
      this.$confirm.require({
        message: "ต้องการออกจากระบบนี้?",
        header: "ออกจากระบบ",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "ออกจากระบบ",
        acceptClass: "p-button-danger",
        acceptIcon: "pi pi-fw pi-power-off",
        rejectLabel: "ยกเลิก",
        accept: async () => {
          localStorage.clear();
          this.$store.commit("ClearLogin");
          // this.$router.push("/login");
        },
      });
    },
  }
}
</script>
